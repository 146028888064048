import React, { createContext, FC, useContext } from 'react'

import { singleton as Transmitigator, TransmitRef } from '@ally/transmitigator'

import { application } from '../../constants'

export interface TransmitContext {
  transmitRef: TransmitRef
}

export const TransmitRefContext = createContext<TransmitContext>(
  {} as TransmitContext,
)

export const useTransmitRef = (): TransmitContext =>
  useContext(TransmitRefContext)

const transmitigator = Transmitigator({
  application,
  server: '/acs/customers/authenticate',
  enableLogs: true,
})

export const TransmitRefProvider: FC = ({ children }) => {
  const hookInstance = transmitigator.useTransmit()
  const transmitRef: TransmitRef = {
    ...transmitigator,
    useTransmit: () => hookInstance,
  }

  return (
    <TransmitRefContext.Provider value={{ transmitRef }}>
      {children}
    </TransmitRefContext.Provider>
  )
}
