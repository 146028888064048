import { useEffect, useRef, useState } from 'react'

import { AllyLaunchDarkly } from '@ally/aardvarkly'
import { LoadState } from '@ally/use-data-domain'
import { accounts as uuipAccounts } from '@ally-financial/uuip-core'
import { Domains } from '@ally/data-types-host'
import log from '../../../whisper'
import { useSession, useHostData, useTransmitRef } from '../../../providers'
import { getAccountTypes, getLOBGroups, isDomainReady } from './utils'

export function useBankLDRegister(instance?: AllyLaunchDarkly): LoadState {
  const isRegistrationPending = useRef(false)
  const [loadState, setRegistrationLoadState] = useState(LoadState.LOADING)

  const { data: sessionData } = useSession()
  const hostData = useHostData()

  const { transmitRef } = useTransmitRef()
  const transmit = transmitRef.useTransmit()

  useEffect(() => {
    if (!instance || isRegistrationPending.current) return
    if (!sessionData || (!sessionData.guid && !sessionData.tpn)) return

    const accountsData = hostData[Domains.AFG_ACCOUNTS]
    if (!isDomainReady(accountsData.status.loadState)) return

    isRegistrationPending.current = true

    const groups = getLOBGroups(sessionData.allyUserRole)
    const accountTypes = getAccountTypes(
      uuipAccounts.selectors.getAllAccounts(
        hostData[Domains.AFG_ACCOUNTS].data,
      ) ?? [],
    )

    instance
      .registerUser(
        sessionData.guid || sessionData.tpn,
        sessionData.userNamePvtEncrypt,
        {
          groups,
          accountTypes,
        },
      )
      .then(({ flags }) => {
        transmit.setFeatureFlags(flags)

        setRegistrationLoadState(LoadState.LOADED)
        log.info({ message: '[USER] Registered with Bank LD Instance' })
      })
      .catch(() => {
        // We are setting this to "LOADED" as some consumers are using this to know
        // whether they can continue evaluation of flags for decisioning
        // Ex: @ally/next-subnav
        setRegistrationLoadState(LoadState.LOADED)
        log.error({
          message: '[USER] Failed to register with Bank LD Instance',
        })
      })
  })

  return loadState
}
