import { useState, useEffect } from 'react'
import warpgate, { WG } from '@ally/warpgate'

import { getLDClientBankMemo } from '../../providers/bootstrap/tasks/features'
import { DEFAULT_EMBER_ROUTES } from './constants'
import { app, isWarpgateEnabled } from './utils'

export const useWarpgate = (): WG | null => {
  const [wg, setWarpgate] = useState<WG | null>(null)
  const isActive = isWarpgateEnabled()
  const { client, getInitPromise } = getLDClientBankMemo()

  useEffect(() => {
    getInitPromise().then(() => {
      setWarpgate(
        warpgate({
          app,
          isActive,
          defaultTarget: 'next',
          getRoutes: () =>
            client.variation('ember-routes', DEFAULT_EMBER_ROUTES),
        }),
      )
    })
  }, [])

  return wg
}
