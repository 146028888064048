/**
 * Array of routes to use as a default when evaluating the 'ember-routes' feature flag
 * This should be updated to match the current 'Production' variation when it changes
 * https://app.launchdarkly.com/projects/default/flags/ember-routes/variations?env=production&env=qa&env=dev&selected-env=production
 */
export const DEFAULT_EMBER_ROUTES = [
  {
    match: '/app-setup',
    target: 'ember',
  },
  {
    match: '/bank/accounts/disputes-redirect/(.*)?',
    target: 'ember',
  },
  {
    match: '/bank/dispute-transaction-summary/(.*)?',
    target: 'ember',
  },
  {
    match: '/bank/(.*)/dispute-transaction/(.*)?',
    target: 'ember',
  },
  {
    match: '/error/page-not-found',
    target: 'ember',
  },
  {
    match: '/error/system-unavailable',
    target: 'ember',
  },
  {
    match: '/open-account/invest-simply/(.*)',
    target: 'next',
  },
  {
    match: '/open-account/(.*)?',
    target: 'ember',
  },
  {
    match: '/temporarily-unavailable',
    target: 'ember',
  },
  'Fallback',
]
